<template>
  <div class="promotion-code-page">
    <div>
      <el-form
        :model="form"
        ref="form"
        label-width="200px"
        style="margin-top: 20px"
      >
        <el-form-item :label="$t('coupon.GeneratePromoCodes')">
          <div style="display: flex">
            <el-input
              v-model="form.code"
              readonly
              style="width: 300px; font-size: 22px"
            ></el-input>
            <el-button
              type="primary"
              @click="generateCode"
              style="margin-left: 20px"
              size="small"
              >{{ $t("coupon.GenerateTenCodes") }}</el-button
            >
          </div>
        </el-form-item>

        <el-form-item :label="$t('coupon.UsableTimes')">
          <el-select
            v-model="form.number"
            :placeholder="$t('coupon.UsableTimes')"
            style="width: 300px"
          >
            <el-option label="50" value="50"></el-option>
            <el-option label="100" value="100"></el-option>
            <el-option label="150" value="150"></el-option>
            <el-option label="200" value="200"></el-option>
            <el-option label="500" value="500"></el-option>
            <el-option
              :label="$t('coupon.UnlimitedFrequency')"
              value="0"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('coupon.CustomDiscount')">
          <el-input
            v-model="form.discount"
            :placeholder="$t('coupon.enterDiscount')"
            style="width: 300px"
            ><template slot="append">%</template></el-input
          >
        </el-form-item>
        
        <el-form-item :label="$t('coupon.CommissionDiscount')">
          <el-input
            v-model="form.commission"
            :placeholder="$t('coupon.CommissionDiscount')"
            style="width: 300px"
            ><template slot="append">%</template></el-input
          >
        </el-form-item>

        <el-form-item :label="$t('coupon.expirationTime')">
          <el-date-picker
            v-model="form.expire"
            type="datetime"
            :placeholder="$t('coupon.SelectTime')"
            style="width: 300px"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item :label="$t('coupon.BindUserId')">
          <div style="display: flex">
            <el-input
              v-model="form.bloggerId"
              style="width: 300px; font-size: 16px"
            ></el-input>
            <el-button
              type="primary"
              @click="getUserId"
              style="margin-left: 20px"
              size="small"
              >{{ $t("coupon.QueryingUserId") }}</el-button
            >
          </div>
        </el-form-item>
        <div style="width: 300px">
          <el-button
            type="primary"
            @click="submitCode"
            style="margin-left: 95%; margin-top: 15px; width: 100px"
            size="medium "
            >{{ $t("common.submit") }}</el-button
          >
        </div>
      </el-form>
    </div>
    <div style="width: 85%; margin-top: 50px; margin-left: 20px">
      <sui-list
        ref="list"
        :column="tableColumn"
        :fun="tableFun"
        :req="tableFunReq"
        @backflow="backflow"
        :isPromoCodes="isPromoCodes"
      />
    </div>
  </div>
</template>
  
<script>
import {
  AddCoupons,
  GetCouponsList,
  DelCoupons,
  GetUserDetailToPhoneOrEmail,
} from "@/utils/api";
import SuiList from "@/components/s-ui/list";
import { formatDataTime } from "@/utils";
export default {
  components: { SuiList },
  data() {
    return {
      form: {
        code: "",
        number: 100,
        discount: "3",
        time: Date.now(),
        id: "",
        commission: "2",
      },
      tableColumn: [
        // 创建时间
        {
          name: "createAt",
          label: "content.exchaterate.createAt",
          formatFn: formatDataTime,
          width: 180,
        },
        // 产品名称
        {
          name: "name",
          label: "productlist.spuname",
          width: 150,
        },
        // 促销码
        {
          name: "code",
          label: "coupon.promoCode",
          width: 150,
        },
        // 折扣
        {
          type: "discount",
          name: "discount",
          label: "coupon.CustomDiscount",
          width: 180,
        },
        // 佣金折扣
        {
          type: "commission",
          name: "commission",
          label: "coupon.CommissionDiscount",
          width: 180,
        },
        // 使用次数
        {
          type: "number",
          name: "number",
          label: "coupon.numberUse",
          width: 180,
        },
        // 绑定用户ID
        {
          type: "number",
          name: "bloggerId",
          label: "coupon.BindUserId",
          width: 250,
        },
      ],
      tableFun: GetCouponsList,
      tableFunReq: {
        page: 1,
        pageSize: this.max || 10,
      },
      isPromoCodes: true,
    };
  },

  methods: {
    getUserId() {
      let isMobile;
      if (this.form.bloggerId) {
        isMobile = this.form.bloggerId.includes(".com");
      }
      GetUserDetailToPhoneOrEmail({
        cond: this.form.bloggerId,
        tType: isMobile ? 1 : 2,
      })
        .then((res) => {
          console.log("res", res);
          this.form.bloggerId = res.data.userId;
          this.$message({
            type: "success",
            message: this.$t("common.optSuccess"),
          });
        })
        .catch((err) => {
          this.form.bloggerId = "";
          console.log(err);

          //   if (err) {
          //     this.$message({
          //       type: "warning",
          //       message: this.$t("common.tryAgain"),
          //     });
          //     this.form.bloggerId = "";
          //   }
        });
    },
    delCode(item) {
      DelCoupons({
        id: item.id,
      })
        .then((res) => {
          console.log("res", res);
          this.$message({
            type: "success",
            message: this.$t("common.deleteSuccess"),
          });
          this.$router.go(0);
        })
        .catch((err) => {
          if (err.errmsg == "no exist") {
            this.$message({
              type: "warning",
              message: this.$t("common.noExist"),
            });
          }
        });
    },
    submitCode() {
      if (this.form.expire) {
        this.form.expire = new Date(this.form.expire).getTime();
      }
      let formData = {
        code: this.form.code,
        discount: Number(this.form.discount),
        number: Number(this.form.number),
        expire: this.form.expire,
        bloggerId: this.form.bloggerId,
        commission: Number(this.form.commission)
      };
      console.log('formData',formData);
      
      AddCoupons(formData)
        .then((res) => {
          console.log("res", res);
          this.$message({
            type: "success",
            message: this.$t("common.addSuccess"),
          });
          this.$router.go(0);
        })
        .catch((err) => {
          if (err.errmsg == "no exist") {
            this.$message({
              type: "warning",
              message: this.$t("common.noExist"),
            });
          }
        });
    },
    generateCode() {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < 10; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      this.form.code = result;
    },
    // 分页
    backflow(count) {
      this.count = count;
    },
  },
};
</script>
  
<style scoped>
.promotion-code-page {
  padding: 20px -20px 20px 20px;
  width: 100%;
  height: 100vh;
  border: 1px solid #dfd5d8;
  border-radius: 2%;
  background: white;
}
</style>
  