const routerViewMap = {
    '/index': () => import('@/views/index'),

    '/account/personal': () => import('@/views/account/personal'),
    '/account/change': () => import('@/views/account/change'),
    '/account/permission': () => import('@/views/account/permission'),
    '/account/roleDatail': () => import('@/views/account/roleDatail'),
    '/account/personnelDatail': () => import('@/views/account/personnelDatail'),

    "/product/add": () => import('@/views/product/add'),
    "/product/edit": () => import('@/views/product/edit'),

    // 获取物流情况
    "/logisticstracking/list": () => import('@/views/logisticstracking/list'),
    "/logisticstracking/edit": () => import('@/views/logisticstracking/edit'),
    "/logisticstracking/detail": () => import('@/views/logisticstracking/detail'),

    "/order/list": () => import('@/views/order/list'),
    "/order/detail": () => import('@/views/order/detail'),

    "/orders/commodity": () => import('@/views/orders/commodity'),
    "/orders/orderDetail": () => import('@/views//orders/orderDetail'),
    "/orders/evaluation": () => import('@/views/orders/evaluation'),
    "/orders/ship": () => import('@/views/orders/ship'),

    "/purchase/procurement": () => import('@/views/purchase/procurement'),
    "/purchase/purchasing": () => import('@/views/purchase/purchasing'),
    "/purchase/purchaseDetail": () => import('@/views/purchase/purchaseDetail'),
    "/purchase/quotation": () => import('@/views/purchase/quotation'),
    "/purchase/procurementDetail": () => import('@/views/purchase/procurementDetail'),

    "/commodity/release": () => import('@/views/commodity/release'),
    "/commodity/releaseimg": () => import('@/views/commodity/releaseimg'),
    "/commodity/products": () => import('@/views/commodity/products'),
    "/commodity/productEdit": () => import('@/views/commodity/productEdit'),
    "/commodity/category": () => import('@/views/commodity/category'),
    "/commodity/brand": () => import('@/views/commodity/brand'),

    "/shop/decorate": () => import('@/views/shop/decorate'),
    "/shop/coupons": () => import('@/views/shop/coupons'),
    "/shop/decoration": () => import('@/views/shop/decoration'),
    "/shop/decorations": () => import('@/views/shop/decorations'),
    "/shop/store": () => import('@/views/shop/store'),
    "/shop/picture": () => import('@/views/shop/picture'),
    "/shop/category": () => import('@/views/shop/category'),
    "/shop/subscription": () => import('@/views/shop/subscription'),

    "/material/list": () => import('@/views/material/list'),
    "/material/category": () => import('@/views/material/category'),

    "/inquiry/inquiry": () => import('@/views/inquiry/chatList'),
    "/inquiry/inquiry_view": () => import('@/views/inquiry/chatDetail'),
    // "/inquiry/inquiry": () => import('@/views/inquiry/inquiry'),
    "/inquiry/reply": () => import('@/views/inquiry/reply'),
    // "/inquiry/inquiry_view": () => import('@/views/inquiry/inquiry_view'),

    "/finances/wallet": () => import('@/views/finances/wallet'),
    "/finances/transactionRecord": () => import('@/views/finances/transactionRecord'),
    "/finances/bankCard": () => import('@/views/finances/bankCard'),
    "/finances/paymentPassword": () => import('@/views/finances/paymentPassword'),

    // "/offer/offer": () => import('@/views/offer/offer'),
    "/pricing/list": () => import('@/views/pricing/list'),
    //汇率列表
    "/exchange_rate/list": () => import('@/views/exchange_rate/list'),
    // 品牌列表
    "/product/brand/list": () => import('@/views/product/brandlist'),
    // 产品列表
    "/product/list": () => import('@/views/product/productlist'),
    // 物流供应商定价编辑
    "/pricing/edit": () => import("@/views/pricing/edit"),
}

export default (item) => {
    return routerViewMap[item.path] || null
}